<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "x3",
  data() {
    return {
      pageName:this.$options.name,
      gnmkList:[
          {
              t1:"灵动表情",
              t2:"多达数十种专属原创表情，让“贝拉”的情感表达更加多样，让你感受更加真实的“猫格”。",
              img:require('@/images/pudu/img11.png'),
          },
          {
              t1:"触感反馈",
              t2:"全新的触控交互，让“贝拉”的反馈更全面，人机体验前所未有。",
              img:require('@/images/pudu/img12.png'),
          },
          {
              t1:"灯光交互",
              t2:"不同任务状态触发对应的灯效交互，状态指示更清晰。",
              img:require('@/images/pudu/img13.png'),
          },
          {
              t1:"AI智能语音",
              t2:"全新加入的AI语音模块，支持上百种专属情境对话内容，让“贝拉”更有趣。",
              img:require('@/images/pudu/img14.png'),
          },
          {
              t1:"设计美学",
              t2:"创新性的仿生式设计，无论是机身高度，屏幕倾角，还是造型曲线，每个角度，都恰到好处。",
              img:require('@/images/pudu/img15.png'),
          },
          {
              t1:"独立联动悬挂",
              t2:"全新研发的车规级联动悬挂，可自适应路面起伏，有效吸收障碍物冲击，实现优秀的越障能力。",
              img:require('@/images/pudu/img16.png'),
          },
      ],
      
    };
  },
  methods: {
    
  },
  mounted() {},
  activated() {}
};
</script>



